"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTimestamp = exports["default"] = void 0;
// This is .js for preval

var versionType = "PROD";
var versionNumber = Math.floor(1738314244311 / 1000);
var shortCommitSha = "5c04cea69";
var getTimestamp = exports.getTimestamp = function getTimestamp(version) {
  return +version.split('-')[1];
};
var _default = exports["default"] = "PROD-1738314244-5c04cea69";